/* eslint-disable */
export default {

  calculateTotals(invoiceData) {
    const data = JSON.parse(JSON.stringify(invoiceData))
    const totals = {
      subtotal: 0,
      total: 0,
      paid: 0,
      discount: 0,
      balance: 0,
      taxes: [],
    }

    let rawTaxTotal = 0
    data.content.items.forEach(val => {
      // eslint-disable-next-line no-param-reassign
      val.amount = val.unit_price * val.quantity

      if (val.discount) {
        if (val.discount.enable) {
          let discountPrice = 0
          if (val.discount.type === 1) {
            discountPrice = ((val.discount.discount / 100) * val.amount)
          } else if (val.discount.type === 2) {
            discountPrice = val.discount.discount
          }

          // eslint-disable-next-line no-param-reassign
          val.amount -= discountPrice
        }
      }
      let itemTaxAmountTotal = 0
      val.applied_taxes.forEach(appliedTax => {
        const filteredTaxses = data.content.taxes.filter(md => md.id === appliedTax.tax_id)

        if (filteredTaxses.length > 0) {
          const filteredTax = filteredTaxses[0]
          // eslint-disable-next-line no-param-reassign
          appliedTax.tax_name = filteredTax.tax_name
          if (appliedTax.tax_rate > 0) {
            // eslint-disable-next-line no-param-reassign
            appliedTax.amount = (val.amount * (appliedTax.tax_rate / 100))
          } else {
            // eslint-disable-next-line no-param-reassign
            appliedTax.amount = 0
          }
          const singleTotalTax = totals.taxes.filter(t => t.tax_id === appliedTax.tax_id && t.tax_rate === appliedTax.tax_rate)

          if (singleTotalTax.length > 0) {
            singleTotalTax[0].amount += appliedTax.amount
          } else {
            totals.taxes.push(JSON.parse(JSON.stringify(appliedTax)))
          }
          itemTaxAmountTotal += appliedTax.amount
        }
        // eslint-disable-next-line no-param-reassign
      })

      totals.subtotal += val.amount

      rawTaxTotal += itemTaxAmountTotal
      totals.discount = 0
    })
    if (data.content.discount) {
      if (data.content.discount.type === 1) {
        totals.discount = ((data.content.discount.discount / 100) * totals.subtotal)
      } else if (data.content.discount.type === 2) {
        totals.discount = data.content.discount.discount
      }
    }
    let discountedTax = 0

    if (totals.discount > 0) {
      discountedTax = (totals.discount / 100) * rawTaxTotal
      const discountRate = totals.discount / totals.subtotal
      discountedTax = rawTaxTotal * (1 - discountRate)
      totals.taxes.forEach(tVal => {
        // eslint-disable-next-line no-param-reassign
        tVal.amount = tVal.amount * (1 - discountRate)
      })
    }
    totals.paid = data.content.paid_amount
    totals.total = (totals.subtotal - totals.discount) + (rawTaxTotal - discountedTax)
    totals.balance = totals.total - totals.paid
    data.content.totals = totals

    return data
  },
}
